.ag-body-viewport {
  overflow-x: hidden;
}

span.de-errors {
  background-color: rgba(255, 86, 48, 0.1);
  margin: 4px 2px 0px 2px;
  min-width: 0;
  box-sizing: border-box;
  padding: 2px 8px;
  display: inline-block;

  color: rgb(255, 86, 48);
  font-size: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  overflow: hidden;
}

.de-alert-wrapper {
  margin-top: 15px;
  margin-bottom: 20px;
}

.de-alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.de-alert {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px;
}
.de-alert ul {
  margin-bottom:0
}

.App .pb-container:not(.loading) > .pb-button {
  border-radius: 4px;
}
/*
.fav-save li::before {
  content: counter(item) " - ";
  counter-increment: item;
}
*/

.de-default-group {
  border-width: 1px;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-style: solid;
  border-radius: 4px;
  border-left-color: #2aabd2;
  border-left-width: 8px;
}

.de-default-group p {
  margin: 4px 0;
}

.de-default-group h3 {
  margin-top: 0;
  font-weight: bold;
} 

.spin-centered {
  margin: 0 auto;
}

.spin-inline {
  display: inline-block;
}

.btn-fav-apply {
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
}

.btn-fav-apply-disabled {
  color: #b9b9b9;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  text-align: center;
}

.btn-fav-apply:active, .btn-fav-apply:hover{
  outline: 0;
}

.btn-fav-apply:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn-fav-apply:hover, .btn-fav-apply:focus {
  color: #23527c;
  text-decoration: underline;
}

.react-confirm-alert-body .react-confirm-alert-button-group button {
  background-color: #337ab7;
}

.dr-page .alert {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px;
}

.dr-page .alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
  background-image: none;
}

.modal .de-modal.modal-dialog {
  position: absolute;
  transform: translate(-50%,0) !important;
  left: 50% !important;
  margin: 0;
}
.modal.de-modal .modal-body {
  padding: 20px 15px;
}

.modal .de-modal .modal-body > p {
  margin-left: 5px;
}

@media (max-width: 767px) {
  .modal .de-modal.modal-dialog  {
    width: 96%;
  }
}

/* FILTER BOX - START */

.dr-page .dr-filterbox .panel-heading {
  padding: 0;
}

.dr-page .dr-filterbox .panel-heading {
  padding: 10px;
  width: 100%;
  display: table;
  text-align: left;
  font-size: 16px;
}

.dr-page .dr-filterbox .panel-heading.panel-collapsedin {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dr-page .dr-filterbox .panel-heading .header-text {
  display: table-cell;
  line-height: 30px;
}

.dr-page .dr-filterbox .panel-heading .header-text svg {
  margin-right: 8px;
}

.dr-page .dr-filterbox .panel-heading .header-buttons {
  text-align: right;
}

.dr-page .dr-filterbox .panel-heading .header-buttons button {
  max-width: 140px;
  width: 100%;
}

.dr-page .dr-filterbox .dr-selector .Select--multi .Select-value {
  font-size: 0.7em;
}

.panel-heading {
  width: 100%;
}

.header-text {
  text-align: left;
  font-size: 16px;
}

div.header-buttons {
  text-align: right;
  font-size: 16px;
}


.panel-body {
  font-size: 12px
}

/* Filtros */

/* Grilla */
div.filter-gallery > div > div.row > div.col-lg-4.col-md-4.col-sm-6.col-xs-12 > div.row > div.col-lg-12.col-md-12.col-sm-12.col-xs-12 {
  padding-top: 0px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
}

/*Barra de Busqueda */
div.filter-gallery > div > div.row > div.col-lg-4.col-md-4.col-sm-6.col-xs-12 > div.row > div.col-lg-10.col-md-10.col-sm-10.col-xs-10 {
  width: 69%;
}

div.filter-gallery > div > div.row > div.col-lg-4.col-md-4.col-sm-6.col-xs-12 > div.row > div.col-lg-2.col-md-2.col-sm-2.col-xs-2 > div.row > div.col-lg-12.col-xs-12 > button {
  width: 50%;
  height: 38px;
  padding-left: 14%;
}

div.filter-gallery > div > div.row > div.col-lg-4.col-md-4.col-sm-6.col-xs-12 > div.row > div.col-xs-12 {
  padding-right: 15px !important;
}

div.generar-galeria > div.pb-container > button.pb-button {
  height: 34px;
  width: 132px;
  padding-top: 6px;
  background-color: #2D6AA0;
  border: 1px;

  position: absolute;
  left: 400px;
  top: -6px;
  z-index: 2;

}

div.generar-galeria > div.pb-container > button.pb-button:disabled {
  height: 34px;
  width: 132px;
  padding-top: 6px;
  background-color: #6F90AE;
  border: 1px;
}


div.generar-galeria > div.pb-container {
  text-align: right;
}

span.text-gallery {
  font-size: 12px;
}

div.generar-galeria > div.pb-container > button.pb-button > span{
  font-size: 14px;
  color: #ffffff;
}
/* FILTER BOX - END */